import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Gallery from '../../components/gallery.jsx';
import Embed from '../../components/embed.jsx';
import voorlater1 from '../../static/img/work/voorlater/voorlater-9.png';
import voorlater2 from '../../static/img/work/voorlater/voorlater-2.png';
import voorlater3 from '../../static/img/work/voorlater/voorlater-3.png';
import voorlater4 from '../../static/img/work/voorlater/voorlater-11.png';
export const _frontmatter = {
  "key": 1,
  "title": "Voor Later",
  "type": "audiovisual",
  "date": "2019",
  "description": "Voor later (For Later) is a video essay made in collaboration with Jorick Buursma and Bas de Haas. It is a video essay about how the emotional value of photos might decline because of the dawn of fast digital photography.",
  "image": "../../static/img/work/voorlater/voorlater-10.png",
  "video": "https://www.youtube.com/embed/Jy4Fg6n4f_4",
  "alt": "film still",
  "runningTime": "8:54",
  "rating": 5,
  "draft": false
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery width={100} mdxType="Gallery">
    <img src={voorlater1} alt="film still" />
    <img src={voorlater2} alt="film still" />
    <img src={voorlater4} alt="film still" />
    <img src={voorlater3} alt="film still" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      